<!-- articleIndexStart -->
<template>
  <div class="articleIndexStart">
    <el-form :model="ruleForm" :rules="rules">
      <el-form-item prop="articleIndexStart" label="起始序号：">
        <el-input class="articleIndexStart-input" v-model="ruleForm.articleIndexStart" style="width:200px;"/>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'articleIndexStart',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
